import {PropsWithChildren} from "react";

interface Props extends PropsWithChildren {
  tip: string;
  color?: 'tooltip-accent' | 'tooltip-primary' | 'tooltip-secondary';
}

export default function Tooltip(props: Props) {
  const position = route().current() !== 'recipe.view'
    ? 'tooltip-bottom'
    : 'tooltip-top';
  return (
    <div className={`tooltip ${position} ${props.color}`} data-tip={props.tip}>
      {props.children}
    </div>
  )
}
