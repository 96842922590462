import {FunctionComponent} from "react";
import RecipeFavoriteButton from "@/Components/RecipeFavoriteButton";
import RecipeMealPlanDialog from "@/Components/Dialogs/RecipeMealPlanDialog";
import {TextLink} from "@/Components/Catalyst/text";
import {BadgeButton} from "@/Components/Catalyst/badge";
import {usePage} from "@inertiajs/react";
import RecipeData = App.Data.RecipeData;

interface RecipeCardProps {
  recipe: RecipeData
  color?: string
}

const RecipeCard: FunctionComponent<RecipeCardProps> = (props) => {
  const {recipe, color} = props
  const pageProps: any = usePage().props

  const cssRingColor = color ? `ring-${color}/5` : 'ring-white/5'
  const cssShadow = color ? `shadow-sm` : ''
  const cssTitleColor = color ? `text-${color}-500` : 'text-white'

  return (
    <div
      key={recipe.uuid}
      className={`flex gap-x-4 rounded-xl bg-white/5 p-4 mx-1 ring-1 ring-inset ${cssRingColor} ${cssShadow}`}
    >
      <div className="text-base leading-7">
        <h3 className="font-semibold flex justify-between">
          {
            <>
              <TextLink
                className={`transition-all ease-linear duration-200 ${cssTitleColor} underline-offset-8 hover:underline-offset-4 data-[hover]:decoration-transparent`}
                href={route('recipe.view', {slug: recipe.slug})}
              >
                {recipe.name}
              </TextLink>
              {
                // User must be logged in and subscribed to show these buttons
                (pageProps.auth?.user || pageProps.auth?.user?.isSubscribedOrOnTrial) &&
                <div className="flex gap-x-2 justify-end ml-2">

                  <RecipeFavoriteButton recipe={recipe}/>
                  <RecipeMealPlanDialog recipe={recipe}/>
                </div>}
            </>

          }
        </h3>
        <p className="mt-2 mb-2 " dangerouslySetInnerHTML={{__html: recipe.excerpt}}/>
        {recipe.image && (
          <div>
            <img
              src={`${recipe.image.filePath}`}
              alt={recipe.name}
              className="w-full h-48 object-cover rounded-lg"
            />
          </div>
        )}
        <div className='mt-3 flex flex-wrap gap-2'>
          {recipe.tags?.map((tag, index) => {
            return (
              <BadgeButton
                key={`tag-${recipe.slug}::${tag.name}`}
                href={route('search', {term: tag.slug})}
                color='white'
                className='transition-all ease-linear duration-200 bg-transparent hover:bg-white text-white hover:text-slate-800'
              >
                {tag.name}
              </BadgeButton>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default RecipeCard
