import {FunctionComponent, useEffect, useState} from "react";
import Api from "@/Api";
import IconButton from "@/Components/Dashboard/UI/IconButton";
import {BookmarkIcon as BookmarkIconOutline, BookmarkSlashIcon} from "@heroicons/react/24/outline";
import {emitCustomEvent} from "react-custom-events";
import Tooltip from "@/Components/Tooltip";
import RecipeData = App.Data.RecipeData;

interface RecipeFavoriteButtonProps {
  recipe: RecipeData,
}

const RecipeFavoriteButton: FunctionComponent<RecipeFavoriteButtonProps> = ({recipe}) => {
  const [isFavorite, setIsFavorite] = useState<boolean>(recipe.isFavorite)
  const [css, setCss] = useState<string>("")

  useEffect(() => {

    setCss(isFavorite
      ? "text-red-600 hover:text-white"
      : "text-white hover:text-yellow-600"
    )
  }, [recipe, isFavorite, setIsFavorite, css, setCss])

  return (
    <Tooltip tip={isFavorite ? 'Un-bookmark Recipe' : 'Bookmark Recipe'}>
      <IconButton
        data-favorite={isFavorite}
        onClick={async (e) => {
          const response = await Api.recipe.toggleFavorite(recipe);

          if (!response.data.errors.length) {
            setIsFavorite(response.data.favorite)
            emitCustomEvent('recipe::bookmarked', response.data)
          }

        }}
      >
        {
          isFavorite
            ? <BookmarkSlashIcon aria-hidden="true"/>
            : <BookmarkIconOutline aria-hidden="true"/>
        }
      </IconButton>
    </Tooltip>
  )
}

export default RecipeFavoriteButton;
