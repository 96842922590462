import {FunctionComponent} from "react";
import RecipeCard from "@/Components/Recipe/Card";
import RecipeData = App.Data.RecipeData;

interface RecipeCardProps {
  recipes: RecipeData[]
  color?: string
}

const CardGrid: FunctionComponent<RecipeCardProps> = (props) => {
  const {recipes, color} = props

  return (
    <div
      className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-4 sm:mt-20 lg:mx-0 lg:max-w-none md:grid-cols-2 lg:grid-cols-3 lg:gap-4">
      {recipes.map((recipe) => (
        <RecipeCard key={recipe.slug} recipe={recipe} color={color}/>
      ))}
    </div>
  )
}

export default CardGrid
